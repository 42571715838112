<template>
  <AppBar>
    <div class="btnBox">
      <el-button type="info" round size="small" @click="whole(1)">全部</el-button>
      <el-button type="info" round size="small" @click="whole(2)">待审核</el-button>
      <el-button type="danger" round size="small"  @click="whole(3)">未通过审核</el-button>
      <!-- <el-button type="success" round size="small">已审核</el-button> -->
    </div>
    <ListData
      isApproval
      :tableData="tableData"
      isBtn="23"
      @RefreshData="RefreshData"
    ></ListData>
    <el-pagination
      style="margin-top: 20px;"
      :hide-on-single-page="true"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="20"
      background
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>
  </AppBar>
</template>
<style lang="less" scoped>
.btnBox {
  margin-bottom: 20px;
  text-align: right;
  button {
    padding: 8px 25px;
    font-size: 14px;
  }
}
[class*=" el-icon-"], [class^=el-icon-]{
  font-size: 18px;
  line-height: 0;
}
.el-icon-more:before{
  font-size: 13px;
}
</style>
<script>
import AppBar from "../../components/AppBar";
import ListData from "../../components/ListData";
export default {
  data() {
    return {
      tableDataCopy: [],
      tableData: [],
      releaseParams : {
        page: 1
      },
      currentPage: 1,
      total: 0,
    };
  },
  methods: {
    // 分页
    handleCurrentChange(page) {
      this.$common.getInterface(
        "/api/v1.projects/getMyPost",
        { state: 2 ,page:page},
        this,
        res1 => {
          // console.log(res);
          // this.tableData = res.data.data;
          this.$common.getInterface(
            "/api/v1.projects/getMyPost",
            { state: 3 ,page:page},
            this,
            res2 => {
              // console.log(res);
              this.tableData = res1.data.data.concat(res2.data.data);
              this.tableDataCopy = res1.data.data.concat(res2.data.data);
              this.total = this.tableData.length;
            }
          );
        }
      );
    },
    whole(state) {
      this.tableData = [];
      this.releaseParams.page = 1;
      this.currentPage = 1;
      if(state == 1) {
        this.tableData = this.tableDataCopy;
      }else {
        this.tableDataCopy.forEach(item => {
          if(item.state == state) {
            this.tableData.push(item);
          }
        })
      }
      this.total = this.tableData.length;
    },
    RefreshData() {
      this.$common.getInterface(
        "/api/v1.projects/getMyPost",
        { state: 2 },
        this,
        res1 => {
          // console.log(res);
          // this.tableData = res.data.data;
          this.$common.getInterface(
            "/api/v1.projects/getMyPost",
            { state: 3 },
            this,
            res2 => {
              // console.log(res);
              this.tableData = res1.data.data.concat(res2.data.data);
              this.total = this.tableData.length;
            }
          );
        }
      );
    }
  },
  created() {
    this.$common.getInterface(
      "/api/v1.projects/getMyPost",
      { state: 2 },
      this,
      res1 => {
        // console.log(res);
        // this.tableData = res.data.data;
        this.$common.getInterface(
          "/api/v1.projects/getMyPost",
          { state: 3 },
          this,
          res2 => {
            // console.log(res);
            this.tableData = res1.data.data.concat(res2.data.data);
            this.tableDataCopy = res1.data.data.concat(res2.data.data);
            this.total = this.tableData.length;
          }
        );
      }
    );
  },
  components: {
    AppBar,
    ListData
  }
};
</script>
